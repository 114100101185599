/* src/components/ConfirmationModal.css */

/* Confirmation Modal styles */
.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  z-index: 1001;
}

.modal-actions button {
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  font-size: 16px;
}

.modal-actions button:first-child {
  background-color: #4caf50;
  color: white;
}

.modal-actions button:last-child {
  background-color: #f44336;
  color: white;
}
