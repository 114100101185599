/* ExerciseTable.css */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: left;
}

.selected-exercise {
  background-color: #f0f8ff; /* Highlight color for selected row */
}

td {
  width: 50px; /* Adjust width for action buttons */
}
