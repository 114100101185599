.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Set a fixed size for uniformity */
  height: 40px; /* Set a fixed size for uniformity */
  font-size: 1.5rem; /* Adjust size of icons */
  text-decoration: none; /* Remove underline for links */
  background: none; /* Ensure buttons and links have no background */
  border: none; /* Remove border from buttons */
  cursor: pointer; /* Add pointer cursor for buttons and links */
  transition: color 0.3s ease; /* Smooth transition for hover effects */
}

.nav-item:hover i {
  color: #666 !important;
}

.nav-item i {
  margin: 0; /* Ensure icons are centered */
  color: #333 !important; /* Default icon color */
}
