/* DeleteConfirmationModal.css */
.delete-confirmation-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .delete-confirmation-modal h3 {
    margin-bottom: 16px;
  }
  
  .delete-confirmation-modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .confirm-button,
  .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirm-button {
    background-color: red;
    color: white;
  }
  
  .cancel-button {
    background-color: gray;
    color: white;
  }
  