/* src/components/MusicTabs.css */
.music-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
    margin-top: 20px;
  }
  
  .tab-titles {
    display: flex;
    cursor: pointer;
  }
  
  .tab-titles button {
    padding: 10px;
    border: none;
    background: lightgray;
    margin-right: 5px;
    transition: background 0.3s;
  cursor: pointer;
  }
  
  .tab-titles button.active {
    background: gray;
  font-weight: bold;
    color: white;
  }
  
  .tab-content {
    border: 1px solid lightgray;
    padding: 10px;
    margin-top: 5px;
  flex-grow: 1; /* Allow this area to take remaining space */
  width: 100%; /* Ensure full width */
}

/* New styles to apply to the layout changes */

/* Spacing between content sections */
.tab-content > div {
  margin-top: 20px;
}

/* Layout for the search box */
.search-box {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 14px;
}

/* Layout for Spotify input and button */
.spotify-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.spotify-link-input {
  width: 70%;
  padding: 10px;
  font-size: 14px;
  margin-right: 10px;
}

.spotify-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

/* Layout for the Create Track button */
.create-row {
  margin-top: 20px;
}

.create-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

/* Additional spacing for Spotify info section */
.spotify-info {
  margin-top: 20px;
}
