/* SessionCreationModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .star-filled {
    color: gold;
  }
  
  .star-empty {
    color: lightgray;
  }
  
  .modal-overlay {
    /* Your existing styles */
  }
  
  .modal-content {
    /* Your existing styles */
  }
  
  .stars {
    display: inline-block;
  }
  
  .linea-vivencia-entry {
    margin-bottom: 10px;
  }
  