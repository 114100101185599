.session-builder {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  padding: 20px;
  max-width: 1200px; /* Set a maximum width for larger screens */
  margin: auto; /* Center the session builder */
  background-color: #fff; /* Add a background color for better contrast */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

h2 {
  color: #333; /* Darker color for headings */
  margin-bottom: 20px; /* Spacing below headings */
}

.table-container {
  display: flex;
  justify-content: space-between; /* Space out tables */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.highlight {
  background-color: #e0f7fa !important; /* Change this color to your preference */
}

.exercise-table, .music-table {
  flex: 1; /* Allow tables to grow and fill available space */
  margin: 10px; /* Spacing around tables */
  min-width: 300px; /* Minimum width to maintain readability */
  border: 1px solid #ddd; /* Border around tables */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Hide overflow for better aesthetics */
}

.exercise-table table, .music-table table {
  width: 100%;
  border-collapse: collapse;
}

.exercise-table th, .music-table th,
.exercise-table td, .music-table td {
  border: 1px solid #ddd;
  padding: 10px; /* Increased padding for better spacing */
  text-align: left; /* Left-align text for consistency */
}

.exercise-table th, .music-table th {
  background-color: #f2f2f2; /* Light background for headers */
  font-weight: bold; /* Bold font for headers */
}

.exercise-table tr:nth-child(even), .music-table tr:nth-child(even) {
  background-color: #fafafa; /* Slightly lighter background for even rows */
}

.exercise-table tr:hover, .music-table tr:hover {
  background-color: #e9e9e9; /* Lighter hover effect */
}

button {
  background-color: #1db954;
  color: white;
  border: none;
  padding: 10px 20px; /* Slightly larger button padding */
  border-radius: 4px; /* Rounded button corners */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

button:hover {
  background-color: #1ed760; /* Change color on hover */
}

/* Responsive styles */
@media (max-width: 768px) {
  .table-container {
      flex-direction: column; /* Stack tables vertically on small screens */
  }

  .exercise-table, .music-table {
      min-width: 100%; /* Full width for mobile screens */
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-content ul {
  list-style: none;
  padding: 0;
}

.modal-content li {
  margin: 10px 0;
  cursor: pointer;
}

.modal-content li:hover {
  background-color: #f0f0f0;
}


.exercise-section {
  display: flex;
  flex-direction: column; /* Stack the table and button vertically */
  flex: 1;
  margin: 10px;
  min-width: 300px;
}

.add-exercise-button {
  background-color: #1db954;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px; /* Add space between the table and the button */
}

.add-exercise-button:hover {
  background-color: #1ed760;
}

.music-tabs {
  flex: 1; /* Take up available space */
  /* Add any additional styling you need for MusicTabs */
}

.move-buttons {
  margin-top: 10px; /* Add some space above the move buttons */
}


.action-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.icon-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.save-button {
  background-color: #4caf50; /* Green */
}

.spotify-button {
  background-color: #1db954; /* Spotify Green */
}

.icon-button:hover {
  opacity: 0.9;
}
