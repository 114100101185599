.linea-vivencia-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .linea-vivencia-table th,
  .linea-vivencia-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .linea-vivencia-entry {
    transition: background-color 0.3s;
  }
  
  .linea-vivencia-entry:hover {
    background-color: #f4f4f4;
  }
  
  .stars {
    font-size: 20px;
  }
  
  .star-filled {
    color: gold;
  }
  
  .star-empty {
    color: #ddd;
  }
  