table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  tr:hover {
    background-color: #f1f1f1; /* Highlight on hover */
  }

  .modal-content {
    /* Existing styles for modal content */
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 900px; /* You can adjust this value to your liking */
    margin: auto; /* Centers the modal */
  }
  
  .wide-modal {
    width: 30%; /* Adjust this percentage to increase or decrease width */
  }